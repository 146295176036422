import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getInitial } from './../services/form.service';
import { useMediaQuery } from 'react-responsive';
import { useStorageFormStore } from '../store';
export const useWelcome = () => {
  const [searchParams] = useSearchParams();
  const vid = searchParams.get('vid');
  const wid = searchParams.get('wid');
  const isMobile = useMediaQuery({ maxWidth: 900 });
  const [isFailed, setIsFailed] = useState(false);
  const [cart, setCart] = useState();
  const [storeName, setStoreName] = useState(null);
  const [storeUrl, setStoreUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const data = useStorageFormStore((state) => state.merchantData);
  const navigate = useNavigate();
  const addMerchant = useStorageFormStore((state) => state.addMerchant);

  async function getInitialState() {
    if (vid && wid) {
      try {
        const result = await getInitial(vid, wid);
        const { merchantName, returnUrl, miniCart } = result;
        setStoreName(merchantName);
        setStoreUrl(returnUrl);
        setCart(miniCart);
        addMerchant(result);
        if (!merchantName || !returnUrl || !miniCart) {
          setIsFailed(true);
        }
      } catch (error) {
        console.log(error);
        setIsFailed(true);
      }
    }
    setIsLoading(false);
  }
  useEffect(() => {
    getInitialState();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (data) {
      navigate(`../instrucciones?vid=${vid}&wid=${wid}`);
    } else {
      navigate(`../fallido?vid=${vid}&wid=${wid}`);
    }
  };

  return {
    isLoading,
    vid,
    wid,
    onSubmit,
    storeName,
    storeUrl,
    acceptedTerms,
    setAcceptedTerms,
    cart,
    isMobile,
    isFailed,
  };
};
