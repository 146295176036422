import React from 'react';
import { loadingScreen } from '../../services/shared/framermotion.service';
import { motion } from 'framer-motion';

export const LoadingSpinnerWompi = ({isLoading}) => {
    return (
        <motion.aside id="spinner-peya" {...loadingScreen} key='loading-spinner'>
            <header>Cargando...</header>
            <span id="imported-loader">
                {/* <SpinnerCircularSplit size={600} thickness={10} speed={40} color="rgba(223, 255, 97, 1)" secondaryColor="rgba(0, 130, 90, 1)" /> */}
            </span>
        </motion.aside>
    );
};
