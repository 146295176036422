import React from 'react'
import failure from '../assets/failure.svg'
import { secondPage } from '../services/shared/framermotion.service'
import { motion } from 'framer-motion'
import { WompiLogo } from '../components/welcome/WompiLogo'
export const Failure = () => {
  return (
    <motion.main {...secondPage} key='main-failure'>
        <WompiLogo/>
        <section id='failure' className='d-flex flex-column align-items-center text-center'>
            <h2>¡No hemos podido procesar tu órden!</h2>
            <div>
                <h3>No ha sido posible completar la creación de códigos para tu pago.</h3>
                <h3>Por favor, vuelve a intentarlo.</h3>
            </div>
            <img src={failure} alt="Fallido" />
            <button>Reintentar</button>
        </section>
    </motion.main>
  )
}
