import { AnimatePresence } from 'framer-motion';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { IndexRoutes } from './Routes/AnimatedRoutes';
import { configure } from 'conexa-core-browser';

configure({
  secretKey: process.env.REACT_APP_CRYPTO_JS,
  debug: process.env.REACT_APP_SCOPE !== 'production',
  env: process.env.REACT_APP_SCOPE,
  securityBypass:
    process.env.REACT_APP_SCOPE !== 'production' && process.env.REACT_APP_SCOPE !== 'stage',
  client: process.env.REACT_APP_CLIENT_ID,
});

// if (process.env.NODE_ENV === 'development') {
// const { worker } = require('./mocks/browser')
// worker.start()
// }
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AnimatePresence>
      <IndexRoutes />
    </AnimatePresence>
  </React.StrictMode>
);
