import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { cartAn } from '../../services/shared/framermotion.service';

export const CartItems = ({ cart={ total: 0, items: [], shippingValue: 0 }, isLoading }) => {
  const { total, items, shippingValue } = cart
  
  return (
    <ul className='cartItemsContainer p-0 rounded'>
      {!isLoading && <>
      <li id='items-container'>
        <ul>
          {items?.map((item,index)=>(
            <AnimatePresence>
              <ListItem  item={item} index={index} key={`item-${index}`}/>
            </AnimatePresence>
          ))}
        </ul>
      </li>
      <motion.li key='delivery-list' className="d-flex flex-row justify-content-between reduced-list">
        <span>Envío</span>
        <span className='shippingValue'>${shippingValue || 'Gratis'}</span>
      </motion.li>
      <motion.li key='total-list' id='total-list-desktop' className=" shippingValue d-flex flex-row justify-content-between reduced-list emphasis">
        <span >Total</span>
        <span>${total || '----'}</span>
      </motion.li>
      </>}  
    </ul>
  );
};

const ListItem = ({item,index}) =>{
  const { name='', quantity=0, price=0, discount=0 } = item
  return (
    <motion.li  {...cartAn(index)} key={`${name}Key${quantity}`} className="d-flex flex-row justify-content-between">
      <span>{name} x{quantity}</span>
      <span>${price-discount}</span>
    </motion.li>
  )
}