import { post as restPost, get as restGet } from './shared/rest.service';

import axios from 'axios';

export const getInitial = async (vid, wid) => {
  try {
    const endpoint = `front/payment-info`;
    const params = { vid: vid, wid: wid };
    const result = await restGet(endpoint, params);
    return result.data;
  } catch (error) {
    throw error.error;
  }
};

export const postCredentials = async (userId, body) => {
  try {
    const endpoint = `/onboarding/authenticate`;
    const params = { userId: userId };
    const result = await restPost(endpoint, body, params);
    return result.data;
  } catch (error) {
    return error;
  }
};

export const postLocations = async (userId, body) => {
  try {
    const endpoint = `/onboarding/validateLocation`;
    const params = { userId: userId };
    const result = await restPost(endpoint, body, params);
    return result.data;
  } catch (error) {
    return error;
  }
};

export const clientIdProps = {
  htmlProps: {
    label: 'Client ID',
    id: 'clientId',
    placeholder: 'FNB9A',
    margins: 'mb-2 mx-4',
  },
};
export const clientSecretProps = {
  htmlProps: {
    label: 'Client Secret',
    id: 'clientSecret',
    placeholder: '************',
    margins: 'mb-2 mx-4',
  },
};

export const postThreeDsForm = async (id) => {
  if (!id) return;
  try {
    const res = await axios.post(`${process.env.REACT_APP_PAYMENTS_API_URL}/ipn/form?id=${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
