import { decryptData } from 'conexa-core-browser';

export const decryptResponse = (data) => {
  try {
    const decrypted = decryptData(data);
    return decrypted.data;
  } catch (error) {
    return undefined;
  }
};
