import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
// const baseUrl= 'http://localhost:3000/'

export const post = async (endpoint, body, params, headers) => {
  try {
    const url = `${baseUrl}${endpoint}`;
    const result = await axios({
      method: 'POST',
      url,
      params: params,
      data: body,
    });
    return result;
  } catch (error) {
    const message = (await error?.response?.data?.error) || 'Ocurrió un error en la conexión';
    throw new Error(message);
  }
};
export const get = async (endpoint, params, headers) => {
  try {
    const url = `${baseUrl}${endpoint}`;
    console.log({ url });
    const result = await axios({
      method: 'GET',
      params: params,
      url,
    });
    return result;
  } catch (error) {
    const message = (await error.response.data.error) || 'Ocurrió un error en la conexión';
    throw new Error(message);
  }
};
