import React from 'react'
import { motion } from 'framer-motion';
import wompiLogo from '../../assets/wompiLogo.svg'
import { Outlet } from 'react-router';
import { wompiLogoAn } from '../../services/shared/framermotion.service';
export const WompiLogo = () => {
    return (
    <motion.aside {...wompiLogoAn} key='logo-container-key' id='wompi-logo' className='my-2 mx-5'>
        <img src={wompiLogo} id='logo' alt="Logo de Wompi" />
    </motion.aside>
    )
}

export const WompiLogoConfirmed = () => {
    return (
    <motion.aside {...wompiLogoAn} key='logo-container-key' id='wompi-logo-confirm' className='my-2 mx-5'>
        <img src={wompiLogo} id='logo-confirm' alt="Logo de Wompi" />
    </motion.aside>
    )
}