import React from 'react';
import { motion } from 'framer-motion';
import {
  buttonWelcome,
  movePage,
  welcomeBar,
  welcomeMessageAn,
} from '../services/shared/framermotion.service';
import { AnimatePresence } from 'framer-motion';
import { useWelcome } from '../hooks/useWelcome';
import { CartItemsMobile } from '../components/welcome/CartItemsMobile';
import { CartItems } from '../components/welcome/CartItems';

import { LocationsContainer } from '../components/welcome/LocationsContainer';
import dollar from '../assets/dollar.png';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { LoadingSpinnerWompi } from '../components/ui/Loading';
import { WompiLogo } from '../components/welcome/WompiLogo';

export const Welcome = () => {
  const {
    isLoading,
    cart,
    onSubmit,
    acceptedTerms,
    setAcceptedTerms,
    storeName,
    storeUrl,
    isMobile,
    isFailed,
  } = useWelcome();

  return (
    <motion.main {...movePage} key="welcome-main-key">
      {isFailed && (
        <strong className="ms-5 ps-5 mt-5 ps-5 text-danger">Ocurrió un error al conectar</strong>
      )}
      <AnimatePresence>
        {!isLoading && <WompiLogo />}
        {isLoading ? (
          <LoadingSpinnerWompi isLoading={isLoading} />
        ) : (
          <motion.section id="welcome-container" className="mt-3" {...welcomeBar}>
            <div className="row ">
              {isMobile && <CartItemsMobile cart={cart} isLoading={isLoading} />}
              <header className="col-12 col-xl-4">
                <motion.h3
                  {...welcomeMessageAn}
                  key="welcome-message-an"
                  className={`mx-xl-5 ${isMobile && 'mobile-welcome-message'}`}
                  id="welcome-message"
                >
                  Acércate a un
                  <strong className="mx-1">Corresponsal bancario "Bancolombia"</strong>y realiza tu
                  pago con las instrucciones que se te brindarán en el siguiente paso.
                </motion.h3>
                <LocationsContainer storeName={storeName} storeUrl={storeUrl} isMobile={isMobile} />
              </header>
              {!isMobile ? (
                <>
                  <div className="col-xl-2" />
                  <form
                    className="col-12 d-flex flex-column align-items-start desktop"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <header>
                      <img src={dollar} alt="Dinero" />
                      <h2 className="fw-bold ">Paga en efectivo</h2>
                    </header>
                    <CartItems cart={cart} isLoading={isLoading} />
                    <fieldset className="d-flex flex-row align-items-center">
                      <input
                        type="checkbox"
                        id="terms-conditions"
                        checked={acceptedTerms}
                        onChange={() => setAcceptedTerms(!acceptedTerms)}
                      />
                      <label htmlFor="terms-conditions" className="mx-xl-3  fw-normal">
                        Acepto los <strong>Términos y condiciones </strong>y la{' '}
                        <strong>Política de privacidad</strong> para hacer esta compra.
                      </label>
                    </fieldset>
                    <motion.button
                      key="terms-key"
                      disabled={!acceptedTerms}
                      className={`isDisabled-${!acceptedTerms}`}
                      type="submit"
                      {...buttonWelcome}
                    >
                      <span>Continuar con el pago</span>
                      <span id="arrow-right">
                        <AiOutlineArrowRight />
                      </span>
                    </motion.button>
                  </form>
                </>
              ) : (
                <form
                  className="col-12 d-flex flex-column align-items-start"
                  onSubmit={(e) => onSubmit(e)}
                >
                  <fieldset className="d-flex flex-row align-items-center mobile">
                    <input
                      type="checkbox"
                      id="terms-conditions"
                      onChange={() => setAcceptedTerms(!acceptedTerms)}
                    />
                    <label htmlFor="terms-conditions" className="mx-xl-3  fw-normal">
                      Acepto los <strong>Términos y condiciones </strong>y la{' '}
                      <strong>Política de privacidad</strong> para hacer esta compra.
                    </label>
                  </fieldset>
                  <motion.button
                    key="terms-key"
                    disabled={!acceptedTerms}
                    className={`isDisabled-${!acceptedTerms} buttonMobile`}
                    type="submit"
                    {...buttonWelcome}
                  >
                    <span>Continuar con el pago</span>
                    <span id="arrow-right">
                      <AiOutlineArrowRight />
                    </span>
                  </motion.button>
                </form>
              )}
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </motion.main>
  );
};
