import React, { useEffect, useState } from 'react';

const PARSER = new DOMParser();

export const IFrameNew = ({ iframe, step, processOK }) => {
  const [decodedHTML] = useState(PARSER.parseFromString(iframe, 'text/html').body.textContent);

  useEffect(() => {
    if (!decodedHTML || processOK) return;
    if (step === 'BROWSER_INFO') {
      const createInputFunctionString = `function createInput${PARSER.parseFromString(
        iframe,
        'text/html'
      )
        .body.textContent?.split('function createInput')[1]
        .replace('</script>', '')
        .slice(0, -1)}
      }`;

      const partialEditedHTML = decodedHTML.split(
        'document.addEventListener("DOMContentLoaded", function () {'
      )[1];
      const finalEditedHTML = partialEditedHTML
        .split('form.submit();')[0]
        .replace(
          'document.body.appendChild(form);',
          'document.querySelector("#root").appendChild(form);'
        );

      const script = document.createElement('script');
      script.id = 'form-script';
      script.textContent = `function createForm () {
        ${finalEditedHTML} 
        ${createInputFunctionString}            
     }
      createForm();
     `;

      if (!document.body.querySelector('#root')?.querySelector('#form-script')) {
        document.body.querySelector('#root')?.appendChild(script);
      }
    }

    if (step === 'FINGERPRINT') {
      const rootElement = document.body.querySelector('#root');
      const prevScript = rootElement?.querySelector('#form-script');
      const prevForm = rootElement?.querySelector('#wompi3dsForm');

      if (rootElement && prevScript) rootElement.removeChild(prevScript);
      if (rootElement && prevForm) rootElement.removeChild(prevForm);

      if (rootElement && decodedHTML) {
        Array.from(PARSER.parseFromString(decodedHTML, 'text/html').body.childNodes).forEach(
          (child) => {
            rootElement.appendChild(child);
          }
        );
      }

      const newForm = document.getElementById('wompi3dsForm');
      newForm?.classList.add('fingerprint-form');
      const script = rootElement?.querySelector('script');
      script?.classList.add('fingerprint-script');

      newForm.submit();
    }

    if (step === 'CHALLENGE') {
      const rootElement = document.body.querySelector('#root');

      const iframeFingerprint = document.getElementById('wompi-3ds-fingerprint-iframe');
      const prevScript = rootElement?.querySelector('.fingerprint-script');
      const prevForm = rootElement?.querySelector('.fingerprint-form');

      if (rootElement && iframeFingerprint) rootElement.removeChild(iframeFingerprint);
      if (rootElement && prevScript) rootElement.removeChild(prevScript);
      if (rootElement && prevForm) rootElement.removeChild(prevForm);

      const newHtml = PARSER.parseFromString(iframe, 'text/html').body.textContent;
      const container3ds = document.querySelector('.container3DS');
      if (rootElement && newHtml && !container3ds) {
        Array.from(PARSER.parseFromString(newHtml, 'text/html').body.childNodes).forEach(
          (child) => {
            rootElement.appendChild(child);
          }
        );
      }

      const formChallenge = document.getElementById('wompi3dsForm');
      if (container3ds) {
        container3ds.style.width = '100vw';
        container3ds.style.height = '100vh';
        container3ds.style.position = 'fixed';
        container3ds.style.top = '0';
        container3ds.style.left = '0';
        container3ds.style.zIndex = '999999';
      }
      formChallenge.submit();
    }
  });

  return <></>;
};
