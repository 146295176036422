import { useState, useEffect } from 'react';
import axios from 'axios';
import { postThreeDsForm } from '../services/form.service';

export const useTransactionData = () => {
  const [finished, setFinished] = useState(false);
  const [returnValues, setReturnValues] = useState({
    data: null,
    isLoading: true,
    iframe: undefined,
    step: '',
    error: null,
    authentication_value: undefined,
    current_step_status: undefined,
    current_step: '',
    fingerprint_info: undefined,
  });

  const [formResState, setFormState] = useState(null);

  useEffect(() => {
    if (
      returnValues.authentication_value !== undefined ||
      returnValues.current_step_status === 'Non-Authenticated'
    )
      return;
    const fetchData = async () => {
      if (finished || !formResState) return;
      try {
        const res = await postThreeDsForm(formResState.tid);
        setReturnValues({
          ...returnValues,
          data: res,
          isLoading: false,
          iframe: res.threeDSAuth.three_ds_method_data,
          step: res.threeDSAuth.current_step,
          statusFinished: res.threeDSAuth.ds_trans_id,
          authentication_value: res.threeDSAuth.authentication_value,
          current_step_status: res.threeDSAuth.current_step_status,
          fingerprint_info: res.threeDSAuth.fingerprint_info,
        });
      } catch (error) {
        setReturnValues({
          ...returnValues,
          error,
          isLoading: false,
        });
      }
    };

    const timeout = setTimeout(() => {
      fetchData();
    }, 2000);

    return () => {
      const source = axios.CancelToken.source();
      source.cancel('Componente desmontado');
      clearTimeout(timeout);
    };
  });

  return { ...returnValues, setFormState, finished, setFinished };
};
