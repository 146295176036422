import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { Collapse } from 'react-bootstrap'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import { MobileCartSummary } from './MobileCartSummary'
export const CartItemsMobile = ({cart, isLoading}) => {
    const { total, items, shippingValue } = cart
    const [ open, setOpen ] = useState(false)
    return (
        <>
            {!isLoading && 
            <ul className='d-flex flex-column ' id='mobileCartContainer'>
                <motion.li key='total-list-mobile' 
                className=" d-flex flex-row  ms-4 me-2 justify-content-between reduced-list emphasis"
                onClick={()=>setOpen(!open)}
                aria-expanded="false" aria-controls="collapseMobileOpener"
                >
                    <span >Total</span>
                    <span >${total} {open ? <IoIosArrowUp color='#000'/> : <IoIosArrowDown color='#000'/> } </span>
                </motion.li>
                <section id='collapseContainer'>
                    <Collapse in={open} id='collapseMobile'>
                        <div id="example-collapse-text">
                            <MobileCartSummary items={items}/>
                        </div>
                    </Collapse>
                </section>
            </ul>
            
            }
        </>
    )
}
