import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { Routes, Route, useLocation, BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.scss';

import { Welcome, FollowUp, Failure } from '../pages/';
import { ThreeDS } from '../pages/Payments';

export const IndexRoutes = () => {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  );
};

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route key="welcome-route" path="corresponsales" element={<Welcome />} />
        <Route key="followup-route" path="instrucciones" element={<FollowUp />} />
        <Route key="failure-route" path="fallido" element={<Failure />} />
        <Route key="payment-route" path="vtex/payments/3ds" element={<ThreeDS />} />
      </Routes>
    </AnimatePresence>
  );
};
