import { motion, AnimatePresence } from 'framer-motion'
import React from 'react'
import { cartAn } from '../../services/shared/framermotion.service'
import dollar from '../../assets/dollar.png'

export const MobileCartSummary = ({items}) => {
  return (
    <summary id='mobileCartSummary'>
      {items?.map((item,index)=>(
          <AnimatePresence>
            <ListItem  item={item} index={index} key={`item-${index}`}/>
          </AnimatePresence>
        ))}
        <footer>
            <img src={dollar} alt="Dinero" />
            <h2 className='fw-bold mt-4 mb-xl-5'>Paga en efectivo</h2>
        </footer>
    </summary>
  )
}


const ListItem = ({item,index}) =>{
  const { name, quantity, price, discount } = item
  return (
      <motion.li  {...cartAn(index)} key={`${name}Key${quantity}`} className="d-flex flex-row justify-content-between">
          <span>{name} x{quantity}</span>
          <span>${price-discount}</span>
      </motion.li>
  )
}