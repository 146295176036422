import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
// import { mountStoreDevtool } from 'simple-zustand-devtools';
//Auth

let auth = set => ({
    alert:{success:null,message:null},
    setAlert: (data) => set(state => ({ alert: data })),
    cleanAlert: () => set(state=>({alert:{success: null, message: null}}))
});

let storageForm = set => ({
    merchantData:{
        merchantName: "",
        returnUrl: '',
        miniCart: null,
        corresponsalesInfo: {
            paymentIntentionIdentifier:"",
            businessAgreementCode: ""
        },
    },
    addMerchant: (data)=>set({merchantData:data})
})


auth = persist(auth, {name: 'shared'});
storageForm = persist(storageForm, {name: 'storage'})
auth = devtools(auth);
storageForm = devtools(storageForm)
export const useAuthStore = create(auth);
export const useStorageFormStore = create(storageForm)