import { useTransactionData } from '../hooks/useTransactionData';
import { decryptResponse } from '../utils/cryptoFunctions';

import { useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { IFrameNew } from '../components/payments/IFrameNew';
import Loader from '../components/payments/Loader';

const NeedData = 'NeedData';

export const ThreeDS = () => {
  const {
    iframe,
    step,
    authentication_value,
    current_step_status,
    statusFinished,
    setFinished,
    setFormState,
  } = useTransactionData();
  const formState = useRef();
  const button = useRef();

  const intervalIdRef = useRef();

  const emitResponse = (message) => {
    console.log('emitResponse', { message });
    window.parent.postMessage(JSON.stringify({ message, fromChild: true }), '*');
  };

  async function submitForm(e) {
    e.preventDefault();

    const actionUrl = e.target.action;

    const data = Array.from(e.target.children).reduce((acc, child) => {
      acc[child.name] = child.value;
      return acc;
    }, {});

    const formData = new FormData();
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        if (key !== '') {
          formData.append(key, data[key]);
        }
      }
    }

    const params = new URLSearchParams();
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        if (key !== '') {
          params.append(key, data[key]);
        }
      }
    }

    try {
      await axios.post(actionUrl, params.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  const startInterval = () => {
    return setInterval(() => {
      const form = document.querySelector('form');
      if (form && !formState.current) {
        formState.current = form;
        formState.current.style.setProperty('display', 'none', 'important');
        formState.current.onsubmit = submitForm;
        const newButton = document.createElement('button');
        newButton.textContent = 'Enviar';
        newButton.type = 'submit';
        formState.current.appendChild(newButton);
        button.current = newButton;
        button.current.click();
      }
    }, 4000);
  };

  const handleMessage = async (event) => {
    if (event.data && typeof event.data === 'string' && event.data !== NeedData) {
      try {
        const cardDataParsed = decryptResponse(event.data);
        if (cardDataParsed) {
          setFormState(cardDataParsed);
          const intervalId = startInterval();
          intervalIdRef.current = intervalId;
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (statusFinished) {
      setFinished(true);
      clearInterval(intervalIdRef.current);
      console.log({ statusFinished });
      emitResponse(statusFinished);
    }
  }, [statusFinished, intervalIdRef]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    emitResponse(NeedData);
  }, []);

  const renderIframe = useMemo(() => {
    const processOK =
      authentication_value || (step === 'CHALLENGE' && current_step_status === 'COMPLETED');
    if (processOK) return <h1>OK</h1>;
    if (current_step_status === 'Non-Authenticated') return <h1>No autenticado</h1>;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className="payment-loader" style={{ marginBottom: '1rem' }}>
          <Loader />
        </div>
        <h1>Cargando...</h1>
        <p style={{ fontSize: '1rem' }}>Este paso puede demorar unos minutos</p>
        {iframe && !processOK && (
          <div style={{ display: step !== 'CHALLENGE' ? 'hidden' : '' }}>
            <IFrameNew iframe={iframe} step={step} processOK={processOK} />
          </div>
        )}
      </div>
    );
  }, [iframe, step, authentication_value, current_step_status]);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'lightblue',
      }}
    >
      {renderIframe}
    </div>
  );
};
