import React from 'react'

export const Reference = ({id,payment}) => {
  return (
    <article className='py-4 px-5 d-flex flex-column my-3'>
      <h4>Número de convenio: <strong>{id || '----'}</strong></h4>
      <h4>Referencia de Pago: <strong>{payment || '----'}</strong></h4>
    </article>
  )
}
