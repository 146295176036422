import React, { useEffect, useState } from 'react';
import { Reference } from './../components/followup/Reference';
import { WompiLogoConfirmed } from '../components/welcome/WompiLogo';
import { motion } from 'framer-motion';
import { useStorageFormStore } from '../store';
import { secondPage } from '../services/shared/framermotion.service';
import { getInitial } from '../services/form.service';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const FollowUp = () => {
  const [searchParams] = useSearchParams();
  const data = useStorageFormStore((state) => state.merchantData);
  const vid = searchParams.get('vid');
  const wid = searchParams.get('wid');
  const { corresponsalesInfo, miniCart } = data;
  const total = miniCart ? miniCart.total : 0;
  const { paymentIntentionIdentifier, businessAgreementCode } = corresponsalesInfo;
  const addMerchant = useStorageFormStore((state) => state.addMerchant);
  const navigate = useNavigate();

  async function getInitialState() {
    if (vid && wid) {
      try {
        const result = await getInitial(vid, wid);
        const { merchantName, returnUrl, miniCart } = result;
        addMerchant(result);
        if (!merchantName || !returnUrl || !miniCart) {
          navigate(`../fallido?vid=${vid}&wid=${wid}`);
        }
      } catch (error) {
        console.log(error);
        navigate(`../fallido?vid=${vid}&wid=${wid}`);
      }
    }
  }

  useEffect(() => {
    if (
      !data.corresponsalesInfo.paymentIntentionIdentifier ||
      !data.corresponsalesInfo.businessAgreementCode
    ) {
      getInitialState();
    }
  }, [data]);

  return (
    <motion.main {...secondPage}>
      <section id="follow-up" className="d-flex flex-column align-items-center text-center">
        <header className="d-flex flex-column align-items-center">
          <WompiLogoConfirmed />
          <h2>Sigue las instrucciones para completar el pago</h2>
          <h4>
            Acércate a un <strong>Corresponsal bancario "Bancolombia"</strong> con los datos
            mostrados a continuación y efectúa el pago en <strong>las próximas 72 horas:</strong>{' '}
          </h4>
        </header>
        <section>
          <h3 className="fw-light">
            Total a pagar:{' '}
            <strong className="fw-bold">
              $<span>{total || '0'}</span>
            </strong>
          </h3>
          <Reference id={businessAgreementCode} payment={paymentIntentionIdentifier} />
        </section>
        <article>
          <h5>
            <a
              download
              target="_blank"
              rel="noopener noreferrer"
              href="https://soporte.wompi.co/hc/es-419/article_attachments/11540401042323"
              className="text-underline"
            >
              Descargar instrucciones
            </a>
          </h5>
        </article>
        <footer className="d-flex flex-column xl-my-5">
          <a
            href="https://www.bancolombia.com/puntos-de-atencion"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="my-3 px-5 py-3 ">Ver sucursales habilitadas</button>
          </a>
          {/* {returnUrl.length > 1 && (
            <a href={returnUrl} target="_blank" rel="noopener noreferrer">
              <button className=" px-5  py-3 btn btn-outline-secondary">
                Regresar a la tienda
              </button>
            </a>
          )} */}
        </footer>
      </section>
    </motion.main>
  );
};
