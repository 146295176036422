import React from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { locationsAn } from '../../services/shared/framermotion.service'
import { motion } from 'framer-motion'

export const LocationsContainer = ({storeName, storeUrl, isMobile}) => {
  return (
    <>{
      !isMobile ?
      <motion.section {...locationsAn} className=' d-flex flex-row py-4 px-xl-2 mx-xl-5 align-items-start'>
        <div className="d-flex flex-column justify-content-start mx-xl-4 ">
          <h3 className='fw-bold '>Pago a <span>{storeName}</span></h3>
          <h5>Efectivo</h5>
        </div>
        {/* <a id='targetUrl' 
        className='d-flex flex-row' 
        href={storeUrl}  
        target='_blank' rel="noreferrer">
          <AiOutlineArrowLeft/>
          <h3>Modificar método de pago</h3>
        </a> */}
      </motion.section>
      :
      <LocationsMobile storeName={storeName} storeUrl={storeUrl}/>}
    </>

  )
}


const LocationsMobile = ({storeName, storeUrl}) => {
  return (
  <motion.section {...locationsAn} className=' d-flex flex-column-reverse align-items-center'>
    <div className="d-flex flex-column justify-content-end mx-xl-4 ">
      <h3 className='fw-bold '>Pago a {storeName}</h3>
      <h5>Efectivo</h5>
    </div>
    <a id='targetUrl' 
    className='d-flex flex-row-reverse' 
    href={storeUrl}  
    target='_blank' rel="noreferrer">
      {/* <AiOutlineArrowLeft/> */}
      {/* <h3>Modificar método de pago</h3> */}
    </a>
  </motion.section>)
}